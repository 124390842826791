import { createStore } from 'vuex';
import axios from 'axios';

import env from '@/helpers/env';

export default {
  namespaced: true,
  state: {
    deFinderItems: [],
    enFinderItems: [],
    deFilters: {},
    enFilters: {},
    items: [],
    activeIndustry: null,
    activeSubindustry: null,
    activeApplication: null,
    activeFilters: {},
  },
  mutations: {
    SET(state, { key, value }) {
      state[key] = value;
    },
    CREATE_ITEM(state, product) {
      state.items.push(product);
    },
    UPDATE_ITEM(state, product) {
      const objIndex = state.items.findIndex((item => item.tessaId === product.tessaId));
      state.items[objIndex] = product;
    },
    DELETE_ITEM(state, itemId) {
      const objIndex = state.items.findIndex((item => item.id === itemId));
      state.items.splice(objIndex, 1);
    },
  },
  actions: {
    async cleanUpDuplicateItems({ state, commit }) {
      const iteratedItems = [];
      state.items.forEach((item) => {
        const itemIndex = iteratedItems.findIndex(i => i.id === item.id);

        if (itemIndex < 0) {
          iteratedItems.push(item);
        } else {
          if (iteratedItems[itemIndex].updated < item.updated) {
            iteratedItems[itemIndex] = item;
          }
        }
      });

      await commit('SET', { key: 'items', value: iteratedItems });
    },
    async cleanupItems({ state, dispatch, commit }, requestedProducts) {
      await dispatch('cleanUpDuplicateItems');
      const itemsForDeletion = [];
      state.items.forEach((item) => {
        if (requestedProducts.filter(rp => rp.sku === item.id).length <= 0) {
          itemsForDeletion.push(item.id);
        }
      });

      itemsForDeletion.forEach((item) => {
        commit('DELETE_ITEM', item);
      });
    },
    async addProductInformation({ state, commit }, products) {
      products.forEach((product) => {
        const update = state.items.find(item => product.sku === item.id);
        if (update) {
          if (product.parent_id) update.category = product.parent_id;
          if (product.image) update.image = product.image;
          if (product.tessaId) update.tessaId = product.tessaId;
          if (product.updated) update.updated = product.updated;
          if (product.sort) update.sort = product.sort;
          commit('UPDATE_ITEM', update);
        } else {
          product.category = product.parent_id;
          product.id = product.sku;
          product.dam_only = true;
          commit('CREATE_ITEM', product);
        }
      });
    },
    async fetchDrupalProducts({ rootState, state, commit, dispatch }) {
      await dispatch('startLoading', 'fetchProducts', { root: true });

      const deApi = env('api_products').replace('{lang}', 'de');
      const enApi = env('api_products').replace('{lang}', 'en');
      const apiUser = env('api_products_user');
      const apiPassword = env('api_products_password');

      const auth = apiUser && apiPassword ? {
        username: apiUser,
        password: apiPassword,
      } : null;

      if (env('disable_requests') === 'false') {

        const deResponse = await axios.get(deApi, {
          auth,
        }).catch((e) => console.log(e));
        const enResponse = await axios.get(enApi, {
          auth,
        }).catch((e) => console.log(e));

        const evaluateResponse = (response, lang) => {
          if (response && response.data && response.data.products) {
            let products = response.data.products;
            products.forEach((product) => {
              // Merge all product fields related to filtering.
              product.filterCategories = [
                ...product.industry,
                ...product.processing,
                ...product.packaging,
                ...product.operating_mode,
                ...product.calibre_weight,
                ...product.shift_operation,
              ];
            });
            commit('SET', { key: `${lang}FinderItems`, value: products });
            commit('SET', { key: `${lang}Filters`, value: response.data.filters });
            // Collect filter machine names and use them as keys
            // bc we need an object as v-model for radios :(
            const sideFilters = response?.data?.filters?.side_filters ?? [];
            const vids = sideFilters.map(({ vid }) => vid);
            commit('SET', {
              key: 'activeFilters',
              value: vids.length ? Object.assign(...vids.map(vid => ({ [vid]: '' }))) : {},
            });
          }
        };

        evaluateResponse(deResponse, 'de');
        evaluateResponse(enResponse, 'en');
      }

      await dispatch('endLoading', 'fetchProducts', { root: true });
    },
    setIndustry({ commit }, industry) {
      commit('SET', { key: 'activeIndustry', value: industry });
      // Reset any previously selected subindustry or application.
      commit('SET', { key: 'activeSubindustry', value: null });
      commit('SET', { key: 'activeApplication', value: null });
    },
    setSubindustry({ commit }, subindustry) {
      commit('SET', { key: 'activeSubindustry', value: subindustry });
      // Reset any previously selected application.
      commit('SET', { key: 'activeApplication', value: null });
    },
    setApplication({ commit }, application) {
      commit('SET', { key: 'activeApplication', value: application });
    },
    setFilters({ commit }, filters) {
      commit('SET', { key: 'activeFilters', value: filters });
    },
    resetIndustry({ commit }) {
      commit('SET', { key: 'activeIndustry', value: null });
      commit('SET', { key: 'activeSubindustry', value: null });
      commit('SET', { key: 'activeApplication', value: null });
    },
    resetAll({ state, commit, dispatch }) {
      dispatch('resetIndustry');
      const clearedFilters = Object.keys(state.activeFilters).reduce(
          (acc, current) => {
            acc[current] = '';
            return acc;
          }, {});
      commit('SET', { key: 'activeFilters', value: clearedFilters });
    },
  },
  getters: {
    getAllProducts(state) {
      const items = state.items;
      return items.sort(function (a, b) {
        return a.sort - b.sort;
      });
    },
    getProducts(state, getters, rootState) {
      // Get active side filter IDs and filter out null/falsy values.
      let ids = Object.values(state[`activeFilters`]).filter(Boolean);
      // Carry on with industry filters.
      [
        state.activeIndustry,
        state.activeSubindustry,
        state.activeApplication,
      ].forEach(item => {
        if (item) {
          ids.push(item.id);
        }
      });
      // Filter only non-tessa products.
      const locale = rootState.locale === -1 ? 'de' : rootState.locale;
      const products = state[`${locale}FinderItems`]?.filter(item => {
        return !item.dam_only;
      }) || [];
      return ids.length ? products.filter(item => {
        return ids.every(id => item.filterCategories.some(category => Number(category.id) === Number(id)));
      }) : products;
    },
    getItemsByCategoryId: (state) => (id) => {
      return state.items.filter(item => item.category === id);
    },
    getProductById: (state) => (id) => {
      return state.items.filter(item => item.id === id)[0];
    },
  },
};
